<template>
  <div>
    <div class="text-center display-1 font-weight-normal mb-6">
      Vamos começar preenchendo as informações básicas
    </div>
    <v-row
      id="atualizacao-descricao"
      class="mx-auto"
    >
      <v-col cols="12">
        <v-text-field
          id="fs-descricao-atualizacao-bdgd"
          label="Descrição (opcional)"
          outlined
          v-model="descricao"
        />
      </v-col>
    </v-row>
    <v-row
      id="atualizacao-empresa"
      class="mx-auto"
    >
      <v-col cols="12">
        <select-company
          id="fs-empresa-atualizacao-bdgd"
          :type="'outlined'"
          :selectedCompany="userLoggedCompany"
          @companySelected="companySelected"
        />
      </v-col>
    </v-row>
    <v-row
      id="atualizacao-versao-bdgd"
      class="mx-auto"
      v-if="companyId"
    >
      <v-col cols="12">
        <select-all-bdgd-versions
          label="Versão da BDGD"
          type="outlined"
          :showIcon="false"
          :companyId="companyId"
          @bdgdVersionSelected="bdgdVersionSelected"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'FirstTabContent',
  components: {
    SelectCompany: () => import('@/components/general/SelectCompany.vue'),
    SelectAllBdgdVersions: () =>
      import('@/components/general/SelectAllBdgdVersions.vue')
  },
  data() {
    return {
      descricao: null,
      companyId: null,
      bdgdVersion: null
    };
  },
  watch: {
    descricao() {
      this.emitEventAtualizacaoUpdated();
    },
    companyId() {
      this.emitEventAtualizacaoUpdated();
    },
    bdgdVersion() {
      this.emitEventAtualizacaoUpdated();
    }
  },
  methods: {
    companySelected(company) {
      this.companyId = company.id;
    },
    bdgdVersionSelected(bdgdVersion) {
      this.bdgdVersion = bdgdVersion;
    },
    emitEventAtualizacaoUpdated() {
      this.$emit('atualizacao:updated', {
        descricao: this.descricao,
        companyId: this.companyId,
        bdgdVersion: this.bdgdVersion
      });
    }
  },
  computed: {
    userLoggedCompany() {
      return this.$store.getters.getSelectedCompany;
    }
  }
};
</script>
